<template>
	<div class="bodily">
		<!-- <h3 class="qjc-fts-16 qjc-c-dark qjc-ftw-500">新生儿信息</h3> -->
		<div class="insert-title">{{$t('open.m21')}}</div>
		
		<el-form
			:model="formData"
			:rules="rules"
			inline
			:hide-required-asterisk="true"
			ref="newbornForm"
		>
			<el-form-item
				:label="$t('open.m7')"
				class="item-100"
				prop="mage"
			>
				<el-input
					v-model="formData.mage"
					placeholder="Please enter"
				></el-input>
				age
			</el-form-item>
			
			<el-form-item
				:label="$t('open.m8')"
				prop="week"
			>
				<el-input
					v-model="formData.week"
					placeholder="Please enter"
				></el-input>
				weeks
			</el-form-item>
			
			<el-form-item
				:label="$t('open.m9')"
				prop="bweight"
			>
				<el-input
					v-model="formData.bweight"
					placeholder="Please enter"
				></el-input>
				g
			</el-form-item>
			
			<el-form-item
				:label="$t('open.m10')"
				prop="bsex"
			>
				<el-select
					v-model="formData.bsex"
				>
					<el-option
						:label="$t('open.t14')"
						:value="1"
					></el-option>
					<el-option
						:label="$t('open.t15')"
						:value="2"
					></el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item
				:label="$t('open.m11')"
				prop="aps1"
				class="apgar"
			>
				<el-input
					v-model="formData.aps1"
					placeholder="Please enter"
				></el-input>
				scores
			</el-form-item>
			
		</el-form>
		<div class="footer-btn">
			<el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">{{$t('open.t12')}}</el-button>
			<el-button @click="next" class="next qjc-fts-16 qjc-block">{{$t('open.t13')}}</el-button>
		</div>
		<!-- <el-button @click="next" class="next qjc-fts-16 qjc-ftw-500">下一步</el-button> -->
		
	</div>
</template>

<script>
	let numberInput = function (value) {
		var price = '' + value;
		price = price
		    .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
		    .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
		    .replace('.', '$#$')
		    .replace(/\./g, '')
		    .replace('$#$', '.')
		    .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
		if (price.indexOf('.') < 0 && price != '') {
		    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
		    price = parseFloat(price);
		}
		return price
	}
	export default{
		name: 'newborn',
		data() {
			var apgarVerify = (rule, value, callback) => {
				if(numberInput(value)) {
					if(numberInput(value) < 0 || numberInput(value) > 10){
						callback(new Error(this.$t('open.m12')));
					}else{
						callback();
					}
				} else {
					callback(new Error(this.$t('open.m12')));
				}
			}
			var weekVerify = (rule, value, callback) => {
				if(numberInput(value)) {
					callback();
				} else {
					callback(new Error(this.$t('open.m13')));
				}
			}
			var mageVerify = (rule, value, callback) => {
				if(numberInput(value)) {
					callback();
				} else {
					callback(new Error(this.$t('open.m14')));
				}
			}
			var bweightVerify = (rule, value, callback) => {
				if(numberInput(value)) {
					callback();
				} else {
					callback(new Error(this.$t('open.m15')));
				}
			}
			
			return {
				rules: {
					bsex: [
						{ required: true, message: this.$t('open.m16'), trigger: 'change' }
					],
					mage: [
						{ required: true, message: this.$t('open.m17'), trigger: 'blur' },
						{ validator: mageVerify, trigger: 'blur' }
					],
					week: [
						{ required: true, message: this.$t('open.m18'), trigger: 'blur' },
						{ validator: weekVerify, trigger: 'blur' }
					],
					bweight: [
						{ required: true, message: this.$t('open.m19'), trigger: 'blur' },
						{ validator: bweightVerify, trigger: 'blur' }
					],
					aps1: [
						{ required: true, message: this.$t('open.m20'), trigger: 'blur' },
						{ validator: apgarVerify, trigger: 'blur' }
					],
				}
			}
		},
		props: ['formData'],
		methods: {
			next() {
				this.$refs.newbornForm.validate((isSuc) => {
					if(isSuc){
						console.log(this.formData);
						this.$emit('checkTab', 'Biochemical');
					}
				});
			},
			handlePrev() {
				this.$emit('checkTab', 'Puerpera');
			},
		}
	}
</script>

<style scoped>
	.el-form >>> .el-form-item__content,
	.el-form >>> .el-form-item__label{
		font-size: 14px;
	}
	.e/* l-form .el-input >>> input, */
	.el-select >>> .el-input__inner{
		width: 200px;
		height: 32px;
		font-size: 14px;
		line-height: 14px;
		padding: 9px 16px;
	}
	.el-form >>> .el-form-item__content,
	.el-form >>> .el-form-item__label{
		vertical-align: middle;
	}
	.el-form >>> .el-form-item__label{
		min-width: 114px;
		line-height: 32px;
		text-align: left;
		padding-right: 0;
	}
	.bodily >>> .el-form-item__label{
		font-size: 14px;
		color: #777F8F;
		padding-right: 10px;
		text-align: left;
		display: block;
		line-height: 30px;
	}
	.bodily >>> .el-input{
		width: 400px;
		height: 34px;
		line-height: 34px;
	}
	.bodily >>> .el-input input{
		width: 400px;
		height: 34px;
		font-size: 14px;
		line-height: 14px;
		padding: 9px 12px;
	}
	.bodily >>> .el-select .el-input .el-select__caret {
		line-height: 24px;
	}
</style>
<style lang="scss" scoped>
	.bodily{
		padding-top: 32px;
		
		.insert-title {
			position: relative;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #293033;
			line-height: 16px;
			padding-bottom: 15px;
			border-bottom: 1px solid #E5E8F5;
			margin-bottom: 32px;
			
			&::before{
				position: absolute;
				left: -24px;
				top: 0;
				content: '';
				width: 4px;
				height: 20px;
				background-color: #6883FB;
			}
		}
		
		.el-form-item{
			// margin: 24px 80px 0 0;
			// min-width: 500px;
			width: 48%;
			
			
		}
		.next-form-item {
			margin-right: 0 !important;
		}
		.footer-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 40px;
		}
				
		.next{
			padding: 0 10px;
			height: 34px;
			border-radius: 17px;
			border: none;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #fff;
		}
		.prev {
			padding: 0 10px;
			height: 34px;
			border-radius: 17px;
			border: 1px solid #6883FB;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #6883FB;
			background-color: #fff;
			margin-right: 24px;
		}
		
		.el-form{
			margin-top: 14px;
			padding-left: 60px;
			
			.el-form-item{
				// margin-right: 0.72px;
				margin-bottom: 20px;
				
				&.apgar{
					// margin-left: 0.2px;
				}
				
				&:nth-child(3n){
					margin-right: 0;
				}
				
				.el-input{
					width: auto;
				}
			}
		}
		
		.item-100 {
			width: 100%;
		}
		
		// .next{
		// 	display: block;
		// 	width: 2px;
		// 	height: 0.48px;
		// 	line-height: 0.48px;
		// 	margin: 0.6rem auto 0;
		// }
	}
</style>
